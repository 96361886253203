.userRowSection {
    height: 35px;
    padding: 5px 40px;
    background: white;
    border: 1.5px dashed #845EC3;
    display: flex;
    align-items: center;
    border-radius: 99px;
    justify-content: space-around;
    .ranking {
      padding: 0 15px;
      border-right: 1px solid white;
      width: 50px;
      color:#845EC3;
    }
  
    .flag {
      width: 50px;
      height: 25px;
      object-fit: contain;
      padding: 0 20px 0 5px;
    }
    .img {
      width: 35;
      height: 35px;
      border-radius: 50%;
      object-fit: contain;
    }
  
    .name {
      padding: 15px;
      width: 150px;
      color:#845EC3;
    }
  
    .amount{
      // margin-right: 50px;
     width: 130px;
      // color: white;
      display: flex;
      align-items: center;
      gap:15px;
      color:#845EC3;
    }
    .score{
      // margin-left: auto;
      width: 125px;
      display: flex;
      color:#845EC3;
      // color: white;
    }
  }

  @media only screen and (max-width: 1490px) {
    .userRowSection{
      justify-content: space-around;
    }
}
  
  @media only screen and (max-width: 515px) {
      .ranking {
        padding: 0 10px !important;
        border-right: 1px solid white;
      
    }
    .name{
      padding: 15px !important;
      color: white;
    }
    
  }
  
  @media only screen and (max-width: 445px) {
    
  .name{
    padding: 5px !important;
    color: white;
  }
  
  }
  
  @media only screen and (max-width: 400px) {
    
    .userRowSection{
      height: 35px;
      width: 96%;
      background-color: rgb(3, 176, 3);
      display: flex;
      align-items: center;
      border-radius: 99px;
      padding: 5px 0px;
  
    }
    .score{
      margin-left: auto;
      color: white;
      padding-right: 10px;
    }
    
    }