.userSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  

  .ImagePart {
    width: 100px;
    height: 100px;
    border: 3px solid;
    border-radius: 50%;
    // overflow: hidden;
  }

  .img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    position: relative;
  }

  .rankingNumber {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -10px;
    left: 40%;
    color: white;
    font-weight: 700;
  }
  


  .box {
    --s: 35px; /* length */
    --o: 0px; /* offset */
    --w: 1px; /* thickness */
    --c: gray; /* color */
    display: inline-block;
  }

  .box span {
    content: "";
    position: absolute;
    width: var(--w);
    left: 50%;
    top: calc(-1 * var(--s) - var(--o));
    bottom: calc(-1 * var(--s) - var(--o));
    border-radius: 5px;
    background: linear-gradient(transparent 0%, lightgray) top,
      linear-gradient(to top, transparent 0%, lightgray) bottom;
    background-size: 100% var(--s);
    background-repeat: no-repeat;
    transform: translate(-50%) rotate(var(--r, 0deg));
  }
  .box span:nth-child(1) {
    --r: 15deg;
  }
  .box span:nth-child(2) {
    --r: 15deg;
  }
  .box span:nth-child(3) {
    --r: -15deg;
  }
  .box span:nth-child(4) {
    --r: 30deg;
  }
  .box span:nth-child(5) {
    --r: -30deg;
  }
  .box span:nth-child(6) {
    --r: 45deg;
  }
  .box span:nth-child(7) {
    --r: -45deg;
  }
  .box span:nth-child(8) {
    --r: 60deg;
  }
  .box span:nth-child(9) {
    --r: -60deg;
  }
  .box span:nth-child(10) {
    --r: 75deg;
  }
  .box span:nth-child(11) {
    --r: -75deg;
  }
  .box span:nth-child(12) {
    --r: 90deg;
  }
  .box span:nth-child(13) {
    --r: 15deg;
  }
  body {
    background: #000;
  }
  p {
    margin: 3px;
    text-align: center;
  }
  .children{
    margin-top:5px;
  }
}

@media only screen and (max-width: 515px) {
  .ImagePart{
    width: 80px !important;
    height: 80px !important;
    border: 3px solid;
    border-radius: 50%;
    // overflow: hidden;
  }

}
