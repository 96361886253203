.userRowSection {
  height: 35px;
  padding: 5px 40px;
  background: white;
  border: 1.5px solid #FF69B4;
  display: flex;
  align-items: center;
  border-radius: 99px;
  .ranking {
    padding: 0 30px;
    border-right: 1px solid white;
  }

  .flag {
    width: 50px;
    height: 25px;
    object-fit: contain;
    padding: 0 20px 0 5px;
  }
  .img {
    width: 35;
    height: 35px;
    border-radius: 50%;
    object-fit: contain;
  }

  .name {
    padding: 30px;
    // color: white;
  }

  .score{
    margin-left: auto;
    // color: white;
  }
}

@media only screen and (max-width: 515px) {
    .ranking {
      padding: 0 10px !important;
      border-right: 1px solid white;
    
  }
  .name{
    padding: 15px !important;
    color: white;
  }
  
}

@media only screen and (max-width: 445px) {
  
.name{
  padding: 5px !important;
  color: white;
}

}

@media only screen and (max-width: 400px) {
  
  .userRowSection{
    height: 35px;
    width: 96%;
    background-color: rgb(3, 176, 3);
    display: flex;
    align-items: center;
    border-radius: 99px;
    padding: 5px 0px;

  }
  .score{
    margin-left: auto;
    color: white;
    padding-right: 10px;
  }
  
  }