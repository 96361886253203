.topUsersSection{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
.users {
  display: flex;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap:50px;

}
h3{
  margin:10px;
}
}
@media only screen and (max-width: 515px) {
  .topUsersSection .users {
    display: flex;
    height: 180px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:20px;
  }

}
