.userListSection{
    padding: 20px;
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-gap: 10px;
}

@media only screen and (max-width: 1080px) {
    .userListSection {
        padding: 20px;
        display: grid;
        grid-template-columns: 1fr ;
        grid-gap: 10px;
    }
  }

  @media only screen and (max-width: 445px) {
    .userListSection {
        padding: 20px;
        display: grid;
     grid-template-columns: 45ch ;
        grid-gap: 10px;
    }
  }

  @media only screen and (max-width: 423px) {
    .userListSection {
        padding: 20px;
        display: grid;
     grid-template-columns: 43ch ;
        grid-gap: 10px;
    }
  }