.topBannerSection {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  background-color: white;
  height: 520px;
  position: relative;
  width:100%;

  h3 {
    margin: 10px;
  }
  .banner {
    // background-color: pink;
    width: 40%;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    z-index: 0;
    height: 150px;

    .championship{
      width: 20%,;
      object-fit: contain;
      position: absolute;
      left: 40%;
      top: 5%;
      z-index: 2;
    }
    .users {
      display: flex;
      height: 220px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 50px;
    }
  }
}
@media only screen and (max-width: 1800px) {
  .championship{

    top: 8% !important;

  }
}

@media only screen and (max-width: 515px) {
  .topUsersSection .users {
    display: flex;
    height: 180px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}
